import React, { useState } from 'react'
import emailjs from '@emailjs/browser';

const Contact = ({ isOpen, onClose }) => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [business, setBusiness] = useState('');

  const handleSubmit = (e) => {
      e.preventDefault();

  const serviceId = 'service_dqusjeb';
  const templateId = 'template_9orsj6a';
  const publicKey = 'y4olKfzplLekR-sPY';

  const templateParams = {
      full_name:  fullName,
      phone_number: phoneNumber,
      address: address,
      to_name: 'SAG logistics',
      website: website,
      business_description: business,
  };
  emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then((response) => {
      console.log('Email sent successfully!', response);
      alert('Message sent, we will get back to you shortly');
      setFullName('');
      setPhoneNumber('');
      setAddress('');
      setWebsite('');
      setBusiness('');
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });

  }
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>The All-In-One Solution for Logistics Businesses</h3>
        <h6 className='txt'>Contact us for automated processes such as booking
            shipments, collecting payments, customer inquiries, 
            and much more. 
        </h6>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Full-Name:
              <input type="text" name="name" 
              placeholder='Full Name'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required />
            </label>
          </div>
          <div >
            <label>
              Phone number:
              <input type="tel" name="phone-number"
              placeholder='Phone Number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required />
            </label>
          </div>
          <div>
            <label>
              Address:
              <input type='text' name="Address" 
              placeholder='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required />
            </label>
          
            <label>
              Website:
              <input type="url" name="website-link"
              placeholder='First Name'
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              />
            </label>
          </div>
          <div >
            <label>Business Description
          <textarea
           cols='30'
           rows='10'
           value={business}
           onChange={(e) => setBusiness(e.target.value)}
    />
    </label>
          </div>
            <div className='sub-button'>
          <button type="submit" className='sub'>Submit</button>
          <button type="button" className='sub' onClick={onClose}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;