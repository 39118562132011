import React, { useState } from 'react';
import image from '../Assets/3pl.jpg';
// import Modal from './Modal';
import Contact from './3pl-contact';
import Ecom from './EcomSection';
const ThirdSection = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

    return (
      <div>
      <div className="pl-background">
        <div className="pl-text pl-item">
<h2>Grow your 3PL Business</h2>
<p>Attract more customers and increase profits by offering competitive international shipping rates.</p>
<button onClick={openModal}> Learn more</button>
<Contact isOpen={isModalOpen} onClose={closeModal} />
        </div>
        <div className="box-picture pl-pic pl-item">
            <img src={image} alt="3pl"/>
        </div>
        
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Ecom/>
      </div>
    );
  };
  
  export default ThirdSection;