import React, { useState } from 'react';
import Logo from "../Assets/logo2.png";
import LogoGrid from "./LogoGrid";
import ThirdSection from "./ThirdSection";
import FAQ from "./FAQ";
import ContactForm from "./Contact-us";
import image from '../Assets/Header-picture.jpg'
import Modal from './Modal';
import Bottom from './Bottom';

const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    
      <div className='body'> <header>
       <div className="main-logo-container">
        <img src={Logo} alt="Logo" className="logo" /> 
        </div>
    
    </header>
    <div>
      <img src={image} alt="SAG logistics delivery" className="header-image"/>
       <div className="header-text">
        <h1 className="Title start">
          Global Shipping for African Businesses
        </h1>
      <div className="Title">
        <h5>
      We link businesses, e-Commerce merchants, 
      and individuals to efficient global shipping 
        </h5>
        <button onClick={openModal}   className="main-button">
        Book now
        </button>
        <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
      
    
    <div>
      <h3 className="Second-section">Get instant access to 20+ local & international delivery partners
      </h3>
    </div>
    <LogoGrid/>
    <br/>
    <br/>
    <br/>
    <ThirdSection/>
    <FAQ/>
    <ContactForm/>
    <Bottom/>
    </div>
    
  )
}

export default Home;
